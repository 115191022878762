import React from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _Store from '_Store';
import { axius, helpers, types } from 'helpers';
import queryString from 'query-string';

const Header = React.lazy(() => import('./Header'));
const Compliance = React.lazy(() => import('../pages/Compliance'));

const Leyout = ({ children }: { children: React.ReactElement }) => {

    const qs = queryString.parse(window.location.search) as any;

    const location = useLocation();
    // const navigate = useNavigate();

    const { _store: { _auth: { user, token } }, _setUser, _setData, _setToken } = React.useContext(_Store);

    const isLoggedIn = !!(user.id && token && !location.pathname.includes('auth'));
    const isCompliant = !!(user.compliance?.email && user.compliance?.phone_number);

    React.useEffect(() => {
        if (token) {
            getMyDetails();
        }
        axius.get('products').then(res => {
            _setData({
                key: 'products',
                value: ((res.data.data || []) as types.Product[]).filter(p => p.code !== 'ANCHORA'),
            });
        });
        // eslint-disable-next-line 
    }, []);

    React.useEffect(() => {
        const signup = helpers.getStorageJson('signup') || {};
        const product = helpers.getStorage('product');
        if (isCompliant && signup.name && product.includes('apk')) {
            helpers.delStorage('signup');
            helpers.redirect(product + `?sso=${token}`);
        }
        // eslint-disable-next-line
    }, [isCompliant]);

    React.useEffect(() => {
        if (Object.keys(qs).length > 0) {
            if (qs.product) {
                helpers.setStorage('product', qs.product);
                !qs.sso && !window?.location?.pathname.toLowerCase().includes('disconnect') && helpers.redirect(window?.location?.origin + window?.location?.pathname);
            }
            if (qs.sso) {
                helpers.loading('authenticating...').then(loading => {
                    _setToken(qs.sso);
                    setTimeout(() => {
                        getMyDetails(() => {
                            helpers.redirect(window?.location?.origin);
                            loading.destroy();
                        });
                    }, 1 * 1000);
                });
            }
            console.log(qs);
        }
        // eslint-disable-next-line
    }, [qs]);

    const getMyDetails = (onOK?: () => void) => {
        axius.get(`users/me`).then(res => {
            if (res.status === 200) {
                _setUser(res.data);
                onOK && onOK();
            }
        });
    }

    return (
        <React.Fragment>
            {(!qs.sso && isLoggedIn) ? (
                <>
                    <Header />
                    <div style={{ minHeight: 'calc(100vh - 135px)' }} className="py-16">
                        {isCompliant ? (
                            <>
                                <div>{children}</div>
                            </>
                        ) : (
                            <div className="hidden-">
                                <Compliance />
                            </div>
                        )}
                    </div>
                    <div className="text-center bg-gray-100 py-4 text-gray-500 text-sm">
                        &copy; {moment().format('YYYY')} Anchora Technologies LTD. All rights reserved.
                    </div>
                </>
            ) : (
                <div>{children}</div>
            )}
        </React.Fragment>
    );
}

export default Leyout;